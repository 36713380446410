<template>
  <div
    :class="`screen-loader${active ? ' active': ''}${fullScreen ? ' fullScreen' : ''}${small ? ' small' : ''}`"
  >
    <div class="v-progress-container text-center">
      <div
        :class="`v-progress-wrapper${sticky ? ' sticky' : ''}`"
      >
        <v-progress-circular
          :size="50"
          :width="5"
          color="primary"
          indeterminate
        />
        <p
          v-if="!!text"
          class="mb-0 mt-4 c-primary"
        >
          {{ text }}
        </p>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">

export default {
  name: 'ScreenLoader',
  props: {
    active: Boolean,
    fullScreen: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.screen-loader {
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    z-index: 100001;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white-54;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
  }

  .v-progress-container {
    position: absolute;
    z-index: 100002;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;

    .v-progress-wrapper {
      display: flex;
      flex-direction: column;
      top: 50%;

      &:not(.sticky) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.sticky {
        position: sticky;
      }
    }

    .v-progress-circular {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.fullScreen {

    &::after,
    > .v-progress-container {
      position: fixed;
    }
  }

  &.fullHeight {
    min-height: 100%;
  }

  &.active {
    min-height: 75px;

    &::after,
    > .v-progress-container {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s;
    }
  }

  &.active.small {
    min-height: 50px;

    .v-progress-circular {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
</style>